import React, { useContext, useEffect, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    ContentTableActionIcon,
    ContentFilterAction,
    ContentTableAction,
    ContentSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import { exposeStrapiError, fixedTwo } from "utils";
import { VwInvestorsFLBalance, vwAplicacoesIndicadores, vwAplicacoesIndicadoresAdmin } from "services/vw";
import useQuote from "hooks/useQuote";
import { CoreContext } from "context/CoreContext";
import { API_ENDPOINT } from "services/api";
import Button from "components/Form/Button";
import usePageTracking from "hooks/usePageTracking.js";

export default function DashboardBalance(){  
    usePageTracking()

    const { selectedQuote, user } = useContext(CoreContext)

    // console.log('user', user.id)

    const { userQuotes } = useQuote()

    const columns = [
        { title:'Fundo', ref:'found' },
        { title:'Vintage', ref:'vintage' },
        { title:'Total de compromisso', ref:'totalcommitment' },
        { title:'Valor integralizado', ref:'commitment' },
        { title:'Amortização aos cotistas', ref:'shareholders' },
        { title:'DPI', ref:'dpi' },
        { title:'TIR', ref:'tir' },
        // { title:'Caixas', ref:'cashiers' },
    ]

    const columnsInvestor = [
        { title: 'Fundo', ref: 'found' }, 
        { title: 'Vintage', ref: 'vintage' }, 
        { title: 'Total de compromisso', ref: 'totalcommitment' }, 
        { title: 'Total aplicado', ref: 'commitment' }, 
        { title: 'Total amortizado', ref: 'shareholders' }, 
        { title: 'DPI', ref: 'dpi' }, 
    ]
    
    const [rows, setRows] = useState([ ])
    const [rowsInvestor, setRowsInvestor] = useState([ ])
    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await VwInvestorsFLBalance()
        // console.log('VwInvestorsFLBalance', result, userQuotes)
        // const result = await Read()

        setRows(!(result && !exposeStrapiError(result) && result.length) ? [] : result.filter(fit => userQuotes?.map(mit => mit.veiculo).includes(fit.veiculo) ).map(item => ({
            id: item?.id, 
            found: item?.veiculo, 
            shareholders: `${ fixedTwo(item?.amortizacao_aos_cotistas) }`, 
            commitment: `${ fixedTwo(item?.compromisso_integralizado) }`, 
            totalcommitment: fixedTwo(item?.total_compromisso), 
            vintage: item?.vintage, 
            // cashiers: item?.box, 
            dpi: `${ fixedTwo(item?.dpi, 2, true) }`,
            tir: `${ parseFloat((item?.tir||0)*100).toFixed(1) }%`
        })))

        console.log(userQuotes)
        console.log(result.filter(fit => userQuotes?.map(mit => mit.veiculo).includes(fit.veiculo) ))
        

        const resultInvestor = user?.spectra && !selectedQuote?.id_origem ? await vwAplicacoesIndicadoresAdmin() : await vwAplicacoesIndicadores(selectedQuote?.id_origem || "")
        const cantSee = !(resultInvestor && !exposeStrapiError(resultInvestor) && resultInvestor.length)

        setRowsInvestor(cantSee ? [] : 
            resultInvestor
                .filter(f => 
                    userQuotes?.map(m => m.veiculo)
                                .includes(f.veiculo) 
                )
        .map(item => ({
            id: item?.id, 
            found: item?.veiculo,
            vintage: item?.vintage, 
            totalcommitment: fixedTwo(item?.compromisso), 
            commitment: `${fixedTwo(item?.total_aplicado)}`, 
            shareholders:`${fixedTwo(item?.total_amortizado)}`, 
            dpi: `${fixedTwo(item?.dpi, 2, true)}`, 
        })))
        
        // console.log('resultInvestor', resultInvestor)
        setLoading(false)
    }

    const exportExcel = async () => { 
        window.open(`${ API_ENDPOINT }/csv/download?omit=["created_at", "updated_at", "created_by", "updated_by", "id"]&table=vw-investidor-fl-caixa`, 'Posição Geral Fundos')
    }

    const sortFund = ( a, b ) => {
        return a.id - b.id
    }

    const clearVintage = vintage => {
        return parseInt(`${ vintage || "" }`.replace("Vintage", "") || 0)
    }

    const sortVintage = ( a, b ) => {
        return clearVintage(b.vintage) - clearVintage(a.vintage)
    }


    useEffect(() => {
        if(userQuotes?.length){ init() ;}
    }, [userQuotes])

    return ( 
        <>
            <ContainerAuthenticated page={"investor/balance"}> 
                <Row>
                    <Col sm={12}>
                        <ContentFilterAction>
                            <DashboardTitle>
                                Posição Geral Fundos
                            </DashboardTitle>  

                            <ContentTableAction>
                                <Button primary nospace small onClick={() => exportExcel()}>
                                    Exportar
                                    <ContentTableActionIcon />
                                </Button>
                            </ContentTableAction>
                        </ContentFilterAction>

                        <BasicTable columns={columns} rows={rows.sort(sortFund).sort(sortVintage)} loading={loading} />
                    </Col>
                    <Col></Col>
                </Row>
                <ContentSpacer />
                <Row>
                    <Col sm={12}>
                        <ContentFilterAction>
                            <DashboardTitle>
                                Posição Geral Cotista
                            </DashboardTitle>  

                        </ContentFilterAction>

                        <BasicTable columns={columnsInvestor} rows={rowsInvestor.sort(sortFund).sort(sortVintage)} loading={loading} />
                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}