import { GET } from './api'

export const VwApplicationFLQuarterly = async () => {
    return await GET(`/vw-aplicacoes-fl-trimestrals?_limit=-1`, true, true);
}

export const VwApplicationIndicators = async () => {
    return await GET(`/vw-aplicacoes-indicadores?_limit=-1`, true, true);
}

export const VwInvestorsFLBalance = async () => {
    return await GET(`/vw-investidor-fl-caixas?_limit=-1`, true, true);
}

export const VwReportsQuotas = async () => {
    return await GET(`/vw-reports-cotas?_limit=-1`, true, true);
}
export const vwAplicacoesIndicadores = async (idCotista) => {
    return await GET(`/vw-aplicacoes-indicadores?_limit=-1&id_cotista=${idCotista}`, true, true);
}

export const vwAplicacoesIndicadoresAdmin = async () => {
    return await GET(`/vw-aplicacoes-indicadores?_limit=-1`, true, true);
}