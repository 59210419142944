import React, { useContext, useEffect, useState } from "react";  

import {  
    DashboardTitle,
    DashboardText,

    PoolLabels,
    LabelContent,
    LabelTitle,
    LabelInfo,
    LabelContentOut,
    LabelContentOutShadow,
    ContentFilter,
    ContentTableAction,
    ContentTableActionIcon,
    ContentFilterAction,
    PoolLabelsSpacer

} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import { ComboChart } from "components/Form/Charts/Combo";

import { Read as ReadV } from "services/cad-vehicles";

import { exposeStrapiError, parseCurrency } from "utils";
import { VwApplicationFLQuarterly, VwApplicationIndicators } from "services/vw";
import { CoreContext } from "context/CoreContext";
import Select from "components/Form/Select";
import useQuote from "hooks/useQuote";
import Button from "components/Form/Button";
import { API_ENDPOINT } from "services/api";
import { useHistory } from "react-router-dom";

export default function DashboardApplications(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const { selectedQuote } = useContext(CoreContext)

    const { userQuotes } = useQuote()

    const dataHeader = [
        "Trimestre",
        "Amortizado",
        "Aplicação",
        "Acumulado",
    ]
    const [data, setData] = useState(null);

    const columns = [
        { title:'Trimestre', ref:'quarter' },
        { title:'Amortizado', ref:'amortization' },
        { title:'Aplicação', ref:'application' },
        { title:'Acumulado', ref:'accumulation' },
    ]
        
    const [labels, setLabels] = useState([])
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)

    const [vehicles, setVehicles] = useState([])
    const [filterVehicles, setFilterVehicles] = useState(null)
    
    const [fullResult, setFullResult] = useState(null) 

    const init = async () => {
        setLoading(true)
         
        const result = await VwApplicationFLQuarterly()

        const resultGraph = await VwApplicationIndicators()

        const resultVehicles = await ReadV()
        setVehicles( resultVehicles.filter(fit => userQuotes?.map(mit => mit.id_veiculo).includes(fit.id_origem)) )

        setFullResult({
            result: result?.length ? 
                result
                    ?.map(m => ({ ...m, trimestre: `${m.trimestre.substr(-1)}T` }) )
                    ?.map( m => ({ ...m, quarter:`${m?.trimestre}/${m?.ano}` }) ) 
                    ?.sort( (a,b) => clearQuarter(a.quarter) - clearQuarter(b.quarter) )
            : result,
            resultGraph,
            resultVehicles
        })

    }

    const filterQuote = fit => {
        return !selectedQuote?.id_origem || fit.id_cotista == selectedQuote?.id_origem || fit.empty
    }

    const injectEmpty = (arr) => {
        if(arr.length){
            const result = []
            for ( let i = 0; i < arr.length ; i++ ){ 
                const current = { ...arr[i] }
                result.push(current)
                const quartersOnYear = arr.filter(f => f.ano === current.ano )
                const pretendQuarters = [1, 2, 3, 4]
                pretendQuarters.forEach(item => {
                    if(!quartersOnYear.map(m => `${ m.trimestre }`).includes(`${item}T`)){

                        const mayNext = { quarter:`${item}T/${current.ano}`, trimestre:`${item}T`, ano:`${current.ano}`, amortizacao: "0", aplicacao: "0", acumulado: "0", empty: true }
                        const quarterDate = new Date(current.ano, (item*3), 1 )
                        quarterDate.setDate(quarterDate.getDate() - 1 )
                        const now = new Date()
                        
                        if( ! result?.filter(f => f.trimestre === mayNext.trimestre && f.ano === mayNext.ano).length && (now.getTime() > quarterDate.getTime()) ){
                            result.push(mayNext)
                        }
                    }
                })
            }
            return ( result?.sort( (a,b) => clearQuarter(a.quarter) - clearQuarter(b.quarter) ) )
        }
        return (arr)
    }

    const zeroPrevMonth = (arr) => {
        const narr = arr.map((item, key) => {

            if(item.amortizacao == "0" && item.aplicacao == "0" && item.acumulado == "0"){
                const value2Assing = findPrevValue(arr, key)
                return {
                    ...item,
                    amortizacao: parseFloat(value2Assing?.amortizacao).toFixed(2),
                    aplicacao: parseFloat(value2Assing?.aplicacao).toFixed(2), 
                    acumulado: parseFloat(value2Assing?.acumulado).toFixed(2),
                }
            } 

            return item
        }) || []

        return narr
    }

    const findPrevValue = (arr, key) => {
        if(key <= 0){
            return arr[0]
        } else {
            if(arr[key - 1].acumulado !== "0"){
                return arr[key - 1]
            } else{
                return findPrevValue(arr, key - 1 )
            }
        }
    }

    const calcularVPL = (fluxosDeCaixa, taxa) => {
        return fluxosDeCaixa.reduce((vpl, fluxo, periodo) => {
            return vpl + fluxo / Math.pow(1 + taxa, periodo);
        }, 0);
    };

    const calcularDerivadaVPL = (fluxosDeCaixa, taxa) => {
        return fluxosDeCaixa.reduce((derivada, fluxo, periodo) => {
            return derivada - (periodo * fluxo) / Math.pow(1 + taxa, periodo + 1);
        }, 0);
    };

    // Função para calcular a TIR
    const calcularTIR = (fluxosDeCaixa, iteracoesMaximas = 10000, tolerancia = 0.0001) => {
        let taxa = 0.1; // Chute inicial mais razoável
        const limiteInferior = -1;
        const limiteSuperior = 1;
        
        for (let i = 0; i < iteracoesMaximas; i++) {
            const vpl = calcularVPL(fluxosDeCaixa, taxa);
            const derivada = calcularDerivadaVPL(fluxosDeCaixa, taxa);
    
            if (Math.abs(vpl) < tolerancia) {
                return (taxa * 100).toFixed(2) + '%';
            }
    
            if (derivada === 0) {
                console.warn("Derivada zero encontrada, tentativa de ajuste falhou.");
                return "N/A";
            }
    
            // Atualiza a taxa
            taxa = taxa - vpl / derivada;
    
            // Se a taxa estiver fora dos limites, aborta
            if (taxa < limiteInferior || taxa > limiteSuperior) {
                console.warn("Taxa saiu dos limites aceitáveis.");
                return "N/A";
            }
        }
    
        console.warn("TIR não convergiu após o número máximo de iterações.");
        return "N/A";
    };
        
    const printView = () => {
        setLoading(true)

        const origin = vehicles?.find(fnd => fnd.id === filterVehicles)?.id_origem ?? null

        const result = ( origin ? fullResult?.result?.filter( fit => fit?.id_veiculo == origin ) : fullResult?.result ).filter(filterQuote)
        const nonEmptyResult = zeroPrevMonth((injectEmpty(( origin ? fullResult?.result?.filter( fit => fit?.id_veiculo == origin ) : fullResult?.result ).filter(filterQuote)) || [])?.reduce(reduceEqualsChart, []))
        const resultGraph = (origin ? fullResult?.resultGraph?.filter( fit => fit?.id_veiculo == origin ) : fullResult?.resultGraph).filter(filterQuote)

        const preRows = !(result && !exposeStrapiError(result) && result.length) ? [] : result.map(item => ({
            quarter:`${item?.trimestre}/${item?.ano}`, 
            amortization: parseCurrency(item?.amortizacao),
            application: parseCurrency(item?.aplicacao), 
            accumulation: parseCurrency(item?.acumulado),
        })) 

        setRows(preRows)
        console.log(rows)

        // No final da função printView, adicione:
        const fluxosDeCaixa = rows.map(row => {
            return parseFloat(row.application.replace(/[^0-9,-]/g, '').replace(',', '.')) - parseFloat(row.amortization.replace(/[^0-9,-]/g, '').replace(',', '.'));
        });

        const nextData = [ dataHeader, ...( !(nonEmptyResult && nonEmptyResult.length) ? [] : nonEmptyResult?.map(item => [`${item?.trimestre}/${item?.ano}`, parseFloat(item?.amortizacao||0), (parseFloat(item?.aplicacao||0) * -1), parseFloat(item?.acumulado||0)] ))]
        setData(nextData?.length === 1 ? [ ...nextData, ['', 0, 0, 0]] : nextData)

        const reduceSum = (p, c) => p + (c||0)

        const totalApplicated = resultGraph?.map( mit => parseFloat(mit?.total_aplicado) )?.reduce(reduceSum, 0 ) 
        const totalAmortized = resultGraph?.map( mit => parseFloat(mit?.total_amortizado) )?.reduce(reduceSum, 0 )
        const totalCommitment = resultGraph?.map( mit => parseFloat(mit?.compromisso) )?.reduce(reduceSum, 0 )
        
        const totalIntegralizado = ((totalApplicated / totalCommitment) * 100)
        const totalDpi = (totalAmortized / totalApplicated)

        setLabels(!(result && result.length) ? [] : [
            { title:'Total aplicado', value: `${ parseCurrency( totalApplicated ) }` },
            { title:'Total amortizado', value: `${ parseCurrency( totalAmortized ) }` },
            { title:'Compromisso', value: `${ parseCurrency( totalCommitment ) }` },
            { title:'Integralizado', value: `${ ( totalIntegralizado )?.toFixed(1) }%` },
            { title:'DPI', value: `${ parseCurrency( totalDpi ) }` },

        ])
        setLoading(false)
    } 

    // { title:'TIR', value: `${ calcularTIR(fluxosDeCaixa) }` },
    
    const exportExcel = async () => { 
        const origin = vehicles?.find(fnd => fnd.id === filterVehicles)?.id_origem ?? null
        if(selectedQuote?.id_origem){
            window.open(`${ API_ENDPOINT }/csv/download?filter={"id_veiculo":${ origin }, "id_cotista":${selectedQuote?.id_origem}}&omit=["created_at", "updated_at", "created_by", "updated_by", "id"]&table=vw-aplicacoes-fl-trimestral`, 'Fluxo de caixa')
        }else{
            window.open(`${ API_ENDPOINT }/csv/download?filter={"id_veiculo":${ origin }}&omit=["created_at", "updated_at", "created_by", "updated_by", "id"]&table=vw-aplicacoes-fl-trimestral`, 'Fluxo de caixa')
        }
    }

    const clearQuarter = quarter => {
        return parseInt( `${ quarter || "" }`.split("T/")?.[1]+`${ quarter || "" }`.split("T/")?.[0] )
    }

    const sortQuarter = ( a, b ) => {
        return clearQuarter(b.quarter) - clearQuarter(a.quarter)
    }

    const clearFloat = string => {
        return typeof string === 'string' ? parseFloat(string.replace(/\./g,'').replace(',','.').replace("R$ ", "")) : string
    }

    const sumObj = (p, c) => {
        let obj = {}
        Object.keys(p).map((i,k) => {
            obj[i] = (i === 'quarter') ? p[i] : parseCurrency(clearFloat(p[i]||'0') + clearFloat(c[i]||'0'))
        })
        return obj
    }

    const sumEquals = (p, c) => {
        return p.reduce((m, n) => sumObj(m,n), c )
    }

    const reduceEquals = (p, c) => {
        const n = (p.map( m => m.quarter ).includes(c.quarter) ? 
            [ ...p.filter( m => m.quarter !== c.quarter ), sumEquals( p.filter( m => m.quarter === c.quarter ), c) ]
        : [...p, c])
        return n 
    }
    
    const sumObjChart = (p, c) => {
        let obj = {}
        Object.keys(p).map((i,k) => {
            obj[i] = (
                i === 'id' 
                || i === 'id_cotista' 
                || i === 'id_origin' 
                || i === 'id_veiculo'
                || i === 'created_at' 
                || i === 'updated_at' 
                || i === 'quarter' 
                || i === 'ano' 
                || i === 'trimestre'
            ) ? p[i] : `${ (parseFloat(p[i]||'0') + parseFloat(c[i]||'0')) }`
        })
        return obj
    }

    const sumEqualsChart = (p, c) => {
        return p.reduce((m, n) => sumObjChart(m,n), c )
    }

    const reduceEqualsChart = (p, c) => {
        const n = (p.map( m => m.quarter ).includes(c.quarter) ? 
            [ ...p.filter( m => m.quarter !== c.quarter ), sumEqualsChart( p.filter( m => m.quarter === c.quarter ), c) ]
        : [...p, c])
        return n 
    }

    useEffect(() => {
        if(userQuotes?.length){ init() ;}
    }, [userQuotes])

    useEffect(() => { 
        if(fullResult?.result && fullResult?.resultGraph && filterVehicles){
            printView()
        }
    }, [ fullResult, filterVehicles ])

    return ( 
        <>
            <ContainerAuthenticated page={"investor/applications"}> 
                <Row>
                    <Col sm={12} md={6}>
                            <DashboardTitle> Fluxo de caixa </DashboardTitle> 
                            { !selectedQuote?.id_origem ? null : <DashboardText> Cotista: { selectedQuote?.title ? ( selectedQuote?.title || '' ) : `Spectra` } </DashboardText>  }

                            <ContentFilterAction>
                                <ContentFilter>
                                    <Select placeholder={"Fundos (Veículos)"} options={[ ...vehicles?.map(item => ({ ...item, title: item.veiculo })), { title:"Geral", id:"general" } ]} value={filterVehicles} onChange={setFilterVehicles} />
                                </ContentFilter>
                                <ContentTableAction>
                                    {
                                        !filterVehicles ? null : <>
                                            <Button primary nospace small onClick={() => exportExcel()}>
                                                Exportar
                                                <ContentTableActionIcon />
                                            </Button>
                                            {/* <Button primary small onClick={() => print() }>
                                                Imprimir
                                                <ContentTableActionPrint />
                                            </Button> */}
                                        </>
                                    }
                                </ContentTableAction>
                            </ContentFilterAction>

                            <DashboardText> &nbsp; </DashboardText> 
                        
                        <div id="printable-content">
                            { filterVehicles && <BasicTable columns={columns} rows={rows?.reduce(reduceEquals, []).sort(sortQuarter)} loading={loading} /> }
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <PoolLabelsSpacer />
                        <div id="printable-header">
                            <PoolLabels>
                                {
                                    labels.map((item, key) => 
                                        <LabelContentOutShadow key={key}>
                                            <LabelContentOut>
                                                <LabelContent>
                                                    <LabelTitle>{ item.title }</LabelTitle>
                                                    <LabelInfo>{ item.value }</LabelInfo>
                                                </LabelContent>
                                            </LabelContentOut>
                                        </LabelContentOutShadow>
                                    )
                                }
                            </PoolLabels>
                            { data && <ComboChart infos={data} /> }
                        </div>

                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}