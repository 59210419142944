import { CoreContext } from 'context/CoreContext'
import React, { useContext, useEffect, useState } from 'react'
import { ReadMe } from 'services/authentication'
import { Read } from 'services/cad-quotes'
import { Read as ReadVWCotistas } from 'services/quote-list'


export default function useQuote(){

    const { user, selectedQuote, setUser } = useContext(CoreContext)
    const [userQuotes, setUserQuotes] = useState([]) 

    const init = async () => {
 
        // console.log("current user", user)
        const updatedUser = await ReadMe() 

        let nextUser = { ...user }
        
        if(updatedUser){
            nextUser = { ...user, ...updatedUser }
        }
        
        const result = await Read()

        if(nextUser?.spectra){
            nextUser = { ...nextUser, cotistas:[ ...result] }
        }

        const resultVw = await ReadVWCotistas()

        const vwSelectedQuote = result?.find(fnd => fnd.id === selectedQuote?.id)?.id_origem

        const quotes =  (nextUser?.cotistas?.map(m => m.id )?.length ? 
            result.filter(fit => 
                nextUser?.cotistas?.filter(ff => !selectedQuote || ff.id_origem == `${vwSelectedQuote}`)?.map(m => m.id)?.includes(fit.id) 
            ) : nextUser?.spectra ? [ ...result ] : [])
            .map( mm => {
                return resultVw?.filter( ff => ff.id_cotista === mm.id_origem) || []
            })?.reduce((p, c) =>  [...p, ...c] , [])

        // console.log('Length', nextUser?.cotistas?.map(m => m.id )?.length, result, nextUser?.cotistas)
        // console.log('User', nextUser, nextUser?.cotistas?.map(m => m.id )?.length , selectedQuote)
        // console.log('All Quote', result)
        // console.log('User Quotes', quotes)
        
        setUserQuotes(quotes);
        setUser(nextUser);
    }

    useEffect(() => {
        init()
    }, [selectedQuote])

    return {
        userQuotes
    }
}