import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { DoLogout } from "services/authentication";

import {
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    DashboardHeaderActionLogo,
    ContentFilter
} from "./styled";
import Autocomplete from "components/Form/Autocomplete";
import { CoreContext } from "context/CoreContext";
import Hotjar from "@hotjar/browser";

export default function DashboardHeader() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { user, setModal, selectedQuote, setSelectedQuote } = useContext(CoreContext)

    const quotes = user?.cotistas?.length ? user?.cotistas : [];

    const exit = async () => {
        try {
            if (user && user.id) {
                Hotjar.identify(user.id.toString(), {
                    email: user.email,
                    username: user.username
                });

                const loginTimestamp = parseInt(localStorage.getItem('loginTimestamp'), 10);
                const logoutTimestamp = new Date().getTime();
                const sessionDuration = logoutTimestamp - loginTimestamp;
                const sessionDurationMinutes = Math.round(sessionDuration / 60000);

                Hotjar.event('session_duration', { duration: sessionDurationMinutes });
            }
        } catch (error) {
            console.error("Hotjar tracking failed", error);
        }

        localStorage.removeItem('loginTimestamp');
        await DoLogout();
        navigate('login');
    };

    return (
        <>
            <DashboardHeaderContainer>
                <DashboardHeaderAction>
                    <DashboardHeaderActionLogo />
                </DashboardHeaderAction>

                {user && user.id ? (
                    <>
                        <ContentFilter>
                            <Autocomplete color={"white"} placeholder={"Cotista"} options={quotes.map(item => ({ ...item, title: item.nome_cotista }))} value={selectedQuote} onChange={setSelectedQuote} />
                        </ContentFilter>

                        <DashboardHeaderAction button onClick={() => setModal({ type: 'pdf', pdf: "/assets/manual-sistema-frontend.pdf" })}>
                            Manual
                        </DashboardHeaderAction>

                        <DashboardHeaderAction button onClick={exit}>
                            <DashboardHeaderActionIcon src={`/icons/door-close.svg`} alt="logout-icon" />
                            Sair
                        </DashboardHeaderAction>
                    </>
                ) : null}

            </DashboardHeaderContainer>
        </>
    );
}
